import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";

import Token from "../quill/Token";
import Editor from "../quill/Editor";
import 'react-quill/dist/quill.snow.css';

import Checkbox from '@material-ui/core/Checkbox';

const TOKENS = [
  { title: "CARPARK", slug: "CARPARK", id: "1" },
  { title: "ELEMENTNAME", slug: "ELEMENTNAME", id: "2" },
  { title: "MESSAGE", slug: "MESSAGE", id: "3" },
  { title: "LASTELEMENTALIVE", slug: "LASTELEMENTALIVE", id: "4" },
  { title: "PARKTIME", slug: "PARKTIME", id: "5" }
];

const tokensById = {};
TOKENS.forEach(token => {
  tokensById[token.id] = token;
});

const EmailNotification = (props) =>  {

let link = localStorage.getItem('link')

const quillRef = useRef(null);

const [recipients, setRecipients] = useState("");
const [contentTemplate, setContentTemplate] = useState("");

const [defectLots, setDefectLots] = useState(false);
const [defectSigns, setDefectSigns] = useState(false);
const [defectGateway, setDefectGateway] = useState(false);
const [lostCarParkCommunication, setLostCarParkCommunication] = useState(false);
const [lotExceeded, setLotExceeded] = useState(false);

useEffect(() => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
}, [localStorage.getItem('JWT')]);


function getEmailNotification() {

    axios.get(link+'/api/EMailNotification', {

        headers: {
            'Content-Type': 'application/json',
        }
      
      }
      )  .then((response) => {

        let data = response.data
        setRecipients(data.recipients);
        setDefectLots(data.defectLots);
        setDefectSigns(data.defectSigns);
        setDefectGateway(data.defectGateway);
        setLostCarParkCommunication(data.lostCarParkCommunication);
        setLotExceeded(data.lotExceeded);

        quillRef.current.setText('');
        quillRef.current.pasteHTML(0, data.contentTemplate);
      })   
      .catch(error => {
        console.error(error);
      })

}

useEffect(() => { 
    getEmailNotification()
 }, []);


function onRecipientsChange(value) {
  setRecipients(value)
}

const handleDefectLotsChange = (e) => {
  if (e.target.checked === true) {
    setDefectLots(true)
  }
  else if (e.target.checked === false) {
    setDefectLots(false)
  }
};

const handleDefectSignsChange = (e) => {
  if (e.target.checked === true) {
    setDefectSigns(true)
  }
  else if (e.target.checked === false) {
    setDefectSigns(false)
  }
};

const handleDefectGatewayChange = (e) => {
  if (e.target.checked === true) {
    setDefectGateway(true)
  }
  else if (e.target.checked === false) {
    setDefectGateway(false)
  }
};

const handleLostCarParkCommunicationChange = (e) => {
  if (e.target.checked === true) {
    setLostCarParkCommunication(true)
  }
  else if (e.target.checked === false) {
    setLostCarParkCommunication(false)
  }
};

const handleLotExceededChange = (e) => {
  if (e.target.checked === true) {
    setLotExceeded(true)
  }
  else if (e.target.checked === false) {
    setLotExceeded(false)
  }
};

function editENTemplate(elementId,value) {

    const data = {
        recipients: recipients,
        contentTemplate: contentTemplate
    }

 axios.put(link+'/api/EMailNotification/Template', data, {

     headers: {
         'Content-Type': 'application/json',
     }
   
   }
   )  .then((response) => {

    setTimeout(getEmailNotification, 1000)
        
   })   
   .catch(error => {

   })

 }

 function editENNotifications(elementId,value) {

  const data = {
      defectLots: defectLots,
      defectSigns: defectSigns,
      defectGateway: defectGateway,
      lostCarParkCommunication: lostCarParkCommunication,
      lotExceeded: lotExceeded
  }

axios.put(link+'/api/EMailNotification/Notifications', data, {

   headers: {
       'Content-Type': 'application/json',
   }
 
 }
 )  .then((response) => {

  setTimeout(getEmailNotification, 1000)
      
 })   
 .catch(error => {

 })

}

return (
    <div className="email-container">

    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="email-header">
        <div>
          An:
          <input type="text" className="email-header-input" value={recipients} onChange={(e) => onRecipientsChange(e.target.value)}></input>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div className="token-list">
          <SimpleBar style={{ height: '100%', width: '100%' }}>
            {TOKENS.map(tokenProps => (
              <div style={{ marginTop: '10px', marginLeft: '5px' }} key={tokenProps.id}>
                <Token {...tokenProps} key={tokenProps.id} quillRef={quillRef} />
              </div>
            ))}
          </SimpleBar>
        </div>
        <div className="quill-box">
        <Editor value={contentTemplate}
          onChange={setContentTemplate}
          quillRef={quillRef} tokensById={tokensById} />
        </div>
      </div>

      <div className="email-footer">
        <button className="button" onClick={()=>editENTemplate()}>{props.t('allgemein.speichern')}</button>
      </div>
    </div>
    <div className="einstellungen-box einstellungen-box-email">
      <div className="box-name">{props.t('administration.personalSettings.emailBenachrichtigungen')}</div>
      <div style={{ padding: '20px' }}>
        <div className="content-group-name">{props.t('emailservice.fehler.fehler')}</div>
        <div className="flex-sb">
          <div>{props.t('emailservice.fehler.StellplatzDefekt')}</div>
          <Checkbox color="primary" size="small" checked={defectLots} onChange={handleDefectLotsChange}/>
        </div>
        <div className="flex-sb">
          <div>{props.t('emailservice.fehler.SchildDefekt')}</div>
          <Checkbox color="primary" size="small" checked={defectSigns} onChange={handleDefectSignsChange}/>
        </div>
        <div className="flex-sb">
          <div>{props.t('emailservice.fehler.VerbindungProcessEngineVerloren')}</div>
          <Checkbox color="primary" size="small" checked={defectGateway} onChange={handleDefectGatewayChange}/>
        </div>
        <div className="flex-sb">
          <div>{props.t('emailservice.fehler.GatewayDefekt')}</div>
          <Checkbox color="primary" size="small" checked={lostCarParkCommunication} onChange={handleLostCarParkCommunicationChange}/>
        </div>
        <div style={{ marginTop: '15px' }} className="content-group-name">{props.t('emailservice.events.events')} </div>
        <div className="flex-sb">
          <div>{props.t('emailservice.events.ZeitueberschreitungParkplatz')}</div>
          <Checkbox color="primary" size="small" checked={lotExceeded} onChange={handleLotExceededChange}/>
        </div>
        
        {/* <div style={{ marginTop: '15px' }} className="content-group-name">{props.t('allgemein.einstellungen')} </div>
        <div className="flex-sb">
          <div>UseSSL</div>
          <Checkbox color="primary" size="small" />
        </div>
        <div className="flex-sb" style={{ marginTop: '10px' }}>
          <div>Server :</div>
          <input type="text" style={{ width: '70%' }}></input>
        </div>
        <div className="flex-sb" style={{ marginTop: '10px' }}>
          <div>Port :</div>
          <input type="text" style={{ width: '70%' }}></input>
        </div>
        <div className="flex-sb" style={{ marginTop: '10px' }}>
          <div>User :</div>
          <input type="text" style={{ width: '70%' }}></input>
        </div>
        <div className="flex-sb" style={{ marginTop: '10px' }}>
          <div>{props.t('login.labelPasswort')} :</div>
          <input type="password" autoComplete="new-password" style={{ width: '70%' }}></input>
        </div> */}

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button className="button" style={{ marginTop: '10px' }} onClick={()=>editENNotifications()}>{props.t('allgemein.speichern')}</button>
        </div>

      </div>
    </div>

    </div>
  )
}

export default withNamespaces()(EmailNotification)